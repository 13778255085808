import React from 'react';
import { Switch, Route } from 'react-router-dom';
import LiabilitiesAndStatementsHome from 'src/admin/navigation/design-system-nav/page-headers/accounting/LiabilitiesAndStatementsHome';
import PayoutsAndBalancesHome from './payouts-and-balances-home';
import InvoicesHome from './invoices-home';
import ReconcileHome from './reconcile-home';
import ChartOfAccountsHome from './chart-of-accounts-home';
import GeneralLedgerHome from './general-ledger-home';
import StatementsHome from './statements-home';
import BookingsSummaryHome from './bookings-summary-home';
import EarningsSummaryHome from './earnings-summary-home';
import BankAccountsHome from './bank-accounts-home';
import InvoicesDetails from './invoices-details';
import BalanceDetails from './balance-details';
import EarningSummaryDetails from './earning-summary-details';
import StatementsDetails from './statements-details';
import StatementHistory from './statement-history';
import ChartOfAccountsSettings from './chart-of-accounts-settings';
import AccountingJournalEntryDetails from './accounting-journal-entry-details';
import BankAccountDetails from './bank-account-details';

const AccountingRoutes = props => {
  return (
    <Switch>
      <Route
        path="/accounting/bank-accounts/:id"
        render={routeProps => (
          <BankAccountDetails
            {...props}
            accountID={routeProps.match.params.id}
          />
        )}
      />
      <Route
        path="/accounting/bank-accounts"
        render={routeProps => <BankAccountsHome {...props} {...routeProps} />}
      />
      <Route
        path="/accounting/earnings-summaries/:payeeId"
        render={routeProps => (
          <EarningSummaryDetails
            {...props}
            {...routeProps}
            payeeId={routeProps.match.params.payeeId}
          />
        )}
      />
      <Route
        path="/accounting/earnings-summaries"
        render={routeProps => (
          <EarningsSummaryHome {...props} {...routeProps} />
        )}
      />
      <Route
        path="/accounting/bookings-summary"
        render={routeProps => (
          <BookingsSummaryHome {...props} {...routeProps} />
        )}
      />
      <Route
        path="/accounting/statements/:id/history"
        render={routeProps => (
          <StatementHistory
            {...props}
            {...routeProps}
            statementId={routeProps.match.params.id}
          />
        )}
      />
      <Route
        path="/accounting/statements/:id"
        render={routeProps => (
          <StatementsDetails
            {...props}
            {...routeProps}
            statementId={routeProps.match.params.id}
          />
        )}
      />
      <Route
        path="/accounting/statements"
        render={routeProps => <StatementsHome {...props} {...routeProps} />}
      />
      <Route
        path="/accounting/chart-of-accounts/general-ledger"
        render={routeProps => <GeneralLedgerHome {...props} {...routeProps} />}
      />
      <Route
        path="/accounting/chart-of-accounts/journal-entries/:id"
        render={routeProps => (
          <AccountingJournalEntryDetails
            {...props}
            {...routeProps}
            entryId={routeProps.match.params.id}
          />
        )}
      />
      <Route
        path="/accounting/chart-of-accounts/settings"
        render={routeProps => (
          <ChartOfAccountsSettings {...props} {...routeProps} />
        )}
      />
      <Route
        path="/accounting/chart-of-accounts"
        render={routeProps => (
          <ChartOfAccountsHome {...props} {...routeProps} />
        )}
      />
      <Route
        path="/accounting/reconcile"
        render={routeProps => <ReconcileHome {...props} {...routeProps} />}
      />
      <Route
        path="/accounting/invoices/:id"
        render={routeProps => (
          <InvoicesDetails {...props} invoiceId={routeProps.match.params.id} />
        )}
      />
      <Route
        path="/accounting/invoices"
        render={routeProps => <InvoicesHome {...props} {...routeProps} />}
      />
      <Route
        path="/accounting/liabilities-and-statements"
        render={routeProps => (
          <LiabilitiesAndStatementsHome {...props} {...routeProps} />
        )}
      />
      <Route
        path="/accounting/balances/:payeeId"
        render={routeProps => (
          <BalanceDetails
            {...props}
            {...routeProps}
            payeeId={routeProps.match.params.payeeId}
          />
        )}
      />
      <Route
        path="/accounting"
        exact
        render={routeProps => (
          <PayoutsAndBalancesHome {...props} {...routeProps} />
        )}
      />
    </Switch>
  );
};

export default AccountingRoutes;
