import { titleize } from "src/shared/utils/display-helpers";
import HoldingAccountBadge from "src/admin/liabilities-and-statements/atoms/HoldingAccountBadge";
import React from "react";
const renderPayeeRole = (payeeRole) => {
  let role = payeeRole;
  if (!role)
    return "No Role Set";
  if (role === "property_contact") {
    role = "property_owner";
  }
  return titleize(role);
};
const renderHoldingAccount = (trustAccounts) => {
  if (!trustAccounts || trustAccounts.length === 0)
    return "N/A";
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(HoldingAccountBadge, {
    account: "stripe"
  }), trustAccounts.map((trust_account) => trust_account.display_name).join("/"));
};
export { renderPayeeRole, renderHoldingAccount };
