import { configureStore } from "@reduxjs/toolkit";
import unifiedInboxSlice from "./slices/unified-inbox/unifiedInbox";
import createBookingSlice from "./slices/createBooking";
import vehicleSlice from "./slices/vehicle";
import createOpportunity from "./slices/createOpportunity";
import websiteCMSSlice from "./slices/websiteCMS";
import bulkEditorSlice from "./slices/bulk-editor/bulkEditor";
import navigationSlice from "./slices/navigation";
import createOrganizationSlice from "./slices/createOrganization";
import filterWidgetSlice from "./slices/filterWidget";
import userDetailsSlice from "./slices/userDetails";
import channelsSlice from "./slices/channels/channels";
import workOrdersSlice from "./slices/workOrder";
import accountingSlice from "./slices/accounting";
import listingsSlice from "./slices/listings";
import reservationsSlice from "./slices/reservations";
import createEmployeeSlice from "./slices/createEmployee";
import createWebsiteSlice from "./slices/createWebsite";
import peopleSlice from "./slices/people";
import reportsSlice from "./slices/reports";
export default configureStore({
  reducer: {
    newBooking: createBookingSlice,
    vehicle: vehicleSlice,
    newOpportunity: createOpportunity,
    websiteCms: websiteCMSSlice,
    unifiedInbox: unifiedInboxSlice,
    bulkEditor: bulkEditorSlice,
    navigation: navigationSlice,
    newOrganization: createOrganizationSlice,
    filterWidget: filterWidgetSlice,
    userDetails: userDetailsSlice,
    channels: channelsSlice,
    workOrders: workOrdersSlice,
    accounting: accountingSlice,
    listings: listingsSlice,
    reservations: reservationsSlice,
    newWebsite: createWebsiteSlice,
    people: peopleSlice,
    newEmployee: createEmployeeSlice,
    reports: reportsSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
});
