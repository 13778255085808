import { Pill } from "@directsoftware/ui-kit-web-admin";
import React from "react";
import moment from "moment/moment";
const getTimeRemaining = (booking) => {
  const expirationDate = moment(booking.created_at).add(1, "days");
  const timeRemaining = moment().isAfter(expirationDate) ? "Expired" : moment(expirationDate).fromNow();
  return timeRemaining;
};
const bookingTypeIcon = (booking, returnLabel = false) => {
  if (booking.booking_type === "friend") {
    return returnLabel ? "Friend of Owner" : "key";
  } else if (booking.booking_type === "owner") {
    return returnLabel ? "Owner Block" : "key";
  } else if (booking.booking_type === "admin") {
    return returnLabel ? "Admin Block" : "lock";
  } else if (booking.booking_type === "maintenance") {
    return returnLabel ? "Maintenance" : "screwdriverWrench";
  } else {
    return returnLabel ? "Guest Reservation" : "conciergeBell";
  }
};
const bookingStatusPill = (booking) => {
  if (booking.confirmed && !booking.cancelled && !booking.archived) {
    return /* @__PURE__ */ React.createElement(Pill, {
      color: "green"
    }, "Confirmed");
  } else if (booking.cancelled && !booking.archived) {
    return /* @__PURE__ */ React.createElement(Pill, {
      color: "red"
    }, "Cancelled");
  } else if (booking.generated_as_type === "request" && !booking.archived) {
    return /* @__PURE__ */ React.createElement(Pill, {
      color: "blue"
    }, "Request");
  } else if (booking.archived) {
    return /* @__PURE__ */ React.createElement(Pill, {
      color: "gray"
    }, "Archived");
  } else if (getTimeRemaining(booking) === "Expired") {
    return /* @__PURE__ */ React.createElement(Pill, {
      color: "gray"
    }, "Expired");
  } else {
    return null;
  }
};
export { bookingTypeIcon, bookingStatusPill };
