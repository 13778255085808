import request from "apiClient";
const list = (orgId, page, query, active) => {
  return request({
    url: `/api/${orgId}/properties`,
    method: "GET",
    params: {
      page,
      q: query,
      active
    }
  });
};
const get = (orgId, propertyId) => {
  return request({
    url: `/api/${orgId}/properties/${propertyId}`,
    method: "GET"
  });
};
const getDetailsPage = (orgId, propertyId) => {
  return request({
    url: `/api/${orgId}/properties/${propertyId}/get-details-page`,
    method: "GET"
  });
};
const loadData = (orgId, propertyId, page) => {
  return request({
    url: `/api/${orgId}/properties/${propertyId}/${page}`,
    method: "GET"
  });
};
const update = (orgId, propertyId, data) => {
  return request({
    url: `/api/${orgId}/properties/${propertyId}`,
    method: "PATCH",
    data
  });
};
const deleteProperty = (orgId, propertyId) => {
  return request({
    url: `/api/${orgId}/properties/${propertyId}`,
    method: "DELETE"
  });
};
const cloneProperty = (orgId, propertyId, data = {}) => {
  return request({
    url: `/api/${orgId}/properties/${propertyId}/clone`,
    method: "PATCH",
    data
  });
};
const getContracts = (orgId, propertyId) => {
  return request({
    url: `/api/${orgId}/properties/${propertyId}/contracts`,
    method: "GET"
  });
};
const getNotes = (orgId, propertyId) => {
  return request({
    url: `/api/${orgId}/notes?notable_class=Property&notable_id=${propertyId}`,
    method: "GET"
  });
};
const getPayees = (orgId, propId) => {
  return request({
    url: `/api/${orgId}/properties/${propId}/employees?type=payee`,
    method: "GET"
  });
};
const getTeamMembers = (orgId, propId) => {
  return request({
    url: `/api/${orgId}/properties/${propId}/employees?type=team_member`,
    method: "GET"
  });
};
const histories = (orgId, propId, { params }) => {
  return request({
    url: `/api/${orgId}/properties/${propId}/history`,
    method: "GET",
    params
  });
};
const incompleteCount = (orgId) => {
  return request({
    url: `/api/${orgId}/properties/incomplete_count`,
    method: "GET"
  });
};
const incomplete = (orgId) => {
  return request({
    url: `/api/${orgId}/properties/incomplete`,
    method: "GET"
  });
};
const PropertyService = {
  get,
  list,
  loadData,
  update,
  deleteProperty,
  cloneProperty,
  getContracts,
  getNotes,
  getPayees,
  getTeamMembers,
  getDetailsPage,
  histories,
  incompleteCount,
  incomplete
};
export default PropertyService;
