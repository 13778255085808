import React, { useEffect, useState } from 'react';
import {
  CallToActionButton,
  IconFontAwesome,
  PageHeader
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch, useSelector } from 'react-redux';
import PermissionComponent from 'PermissionComponent';
import { UnitService } from 'adminApi';
import { LISTINGS_NAV_TAGS } from '../../../../../shared/nav';
import ManageUnitCommandPalette from '../../../../pages/DashboardUnit/molecules/manage-unit-command-palette';
import PropertyService from '../../../../api/PropertyService';
import { updateListingImages } from '../../../../redux/slices/listings';

const UnitDetails = props => {
  const dispatch = useDispatch();
  const activeRoutes = useSelector(state => state.navigation.activeRoutes);
  const [propertyProps, setPropertyProps] = useState(props.property);
  const [unitProps, setUnitProps] = useState(props.unit);
  const [isMultiUnit, setIsMultiUnit] = useState(true);
  const [isImagesPage, setIsImagesPage] = useState(false);

  useEffect(
    () => {
      setIsMultiUnit(props?.activeProperty?.multi_unit);
    },
    [props, props.location]
  );

  useEffect(
    () => {
      if (!propertyProps) {
        PropertyService.get(props.organization.id, props.propertyId).then(
          response => {
            setPropertyProps(response.property);
          }
        );
      }
      if (!unitProps && props.unitId !== 'new') {
        UnitService.getUnitInfo(props.organization.id, props.unitId).then(
          response => {
            setUnitProps(response.unit);
          }
        );
      }
      setIsImagesPage(
        activeRoutes.tertiary === LISTINGS_NAV_TAGS.tertiary.IMAGES
      );
    },
    [props]
  );

  return props.activeProperty ? (
    <>
      <PageHeader height="l" containerVariation="fluid">
        {isMultiUnit ? (
          <PageHeader.Left
            breadcrumbs={[
              {
                href: '/properties',
                label: 'Listings: Properties'
              },
              {
                href: `/properties/${props.activeProperty.id}`,
                label: 'Parent Property'
              },
              {
                label: 'Unit Details'
              }
            ]}
            backLinkHref={
              isImagesPage
                ? `/properties/${props.activeProperty.id}/units/${props.unitId}`
                : null
            }
          >
            {props.activeUnit?.name}
          </PageHeader.Left>
        ) : (
          <PageHeader.Left
            breadcrumbs={[
              {
                href: '/properties',
                label: 'Listings: Properties'
              },
              {
                label: `Details (Single Unit)`
              }
            ]}
          >
            {props.activeProperty.name}
          </PageHeader.Left>
        )}
        {isImagesPage && (
          <PageHeader.Right>
            <CallToActionButton
              onClick={() => {
                dispatch(updateListingImages({ uploadUnitPhotos: true }));
              }}
              prependIcon={<IconFontAwesome name="upload" />}
            >
              Upload
            </CallToActionButton>
          </PageHeader.Right>
        )}
        {activeRoutes.tertiary === LISTINGS_NAV_TAGS.tertiary.OVERVIEW &&
          unitProps && (
            <PageHeader.Right>
              <PermissionComponent
                user={props.user}
                permission="view_properties"
                restriction="properties_read_only"
              >
                <ManageUnitCommandPalette
                  organization={props.organization}
                  property={propertyProps}
                  unit={unitProps}
                  user={props.user}
                />
              </PermissionComponent>
            </PageHeader.Right>
          )}
      </PageHeader>
    </>
  ) : null;
};

export default UnitDetails;
