import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { MESSAGING_NAV_TAGS } from '../../../../../shared/nav';

const RvshareAutoMessages = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: MESSAGING_NAV_TAGS.PRIMARY,
        secondary: MESSAGING_NAV_TAGS.secondary.RVSHARE_AUTO_MESSAGES
      })
    );
  }, []);

  return (
    <PageHeader>
      <PageHeader.Left>
        Messaging: <span>RVshare Auto Messages</span>
      </PageHeader.Left>
    </PageHeader>
  );
};

export default RvshareAutoMessages;
