import React from 'react';
import { Navbar } from '@directsoftware/ui-kit-web-admin';
import LinksDesktop from './links-desktop';

const MyAccountDesktopLinkMenu = props => {
  return (
    <Navbar.LinkMenu
      primaryLinkType="navbarIconLink"
      primaryLinkProps={{
        isActive: props.activeRoutes.primary === props.navTags.PRIMARY,
        iconName: 'userCircle',
        type: 'button'
      }}
      menuTrigger="hover"
      alignPanel="right"
      className="pw-my-account-desktop"
    >
      <LinksDesktop {...props} />
    </Navbar.LinkMenu>
  );
};

export default MyAccountDesktopLinkMenu;
