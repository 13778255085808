import moment from "moment/moment";
const propertyOwnerYearlyEarningOptions = [];
let i = 0;
while (i < 10) {
  propertyOwnerYearlyEarningOptions.push({
    label: moment().subtract(i, "years").startOf("year").startOf("day").format("YYYY"),
    value: moment().subtract(i, "years").startOf("year").startOf("day")
  });
  if (moment().subtract(i, "years").format("YYYY") === "2021")
    i = 11;
  i++;
}
const accountingPayeeRoleOptions = [
  { value: "", label: "All", filter: "role" },
  { value: "property_contact", label: "Property Owner", filter: "role" },
  { value: "housekeeping", label: "Housekeeper", filter: "role" },
  { value: "maintenance", label: "Maintenance", filter: "role" }
];
const accountingBalanceStatusOptions = [
  { value: "", label: "All", filter: "balance" },
  { value: "positive", label: "Positive", filter: "balance" },
  { value: "negative", label: "Negative", filter: "balance" },
  { value: "zero", label: "0.00", filter: "balance" }
];
const accountingBuildPortfolioOptions = (portfolios) => {
  return portfolios.map((portfolio) => {
    return {
      value: portfolio.id,
      label: portfolio.name,
      filter: "portfolio"
    };
  });
};
export {
  propertyOwnerYearlyEarningOptions,
  accountingBalanceStatusOptions,
  accountingPayeeRoleOptions,
  accountingBuildPortfolioOptions
};
