import React from 'react';
import { Route, Switch } from 'react-router-dom';
import DirectCustomizableEmails from './direct-customizable-emails';
import DirectSystemEmails from './direct-system-emails';
import VrboAutoMessages from './vrbo-auto-messsages';
import AirbnbAutoMessages from './airbnb-auto-message';
import DirectCustomEmailDetail from './direct-custom-email-detail';
import VrboEmailDetail from './vrbo-email-detail';
import DirectSystemEmailDetail from './direct-system-email-detail';
import AirbnbEmailDetail from './airbnb-message-detail';
import RvshareAutoMessages from './rvshare-auto-messages';
import RvshareEmailDetail from './rvshare-email-detail';

const MessagingRoutes = props => {
  return (
    <Switch>
      <Route
        path="/messaging/airbnb-custom-auto-messages/:notificationId"
        render={routeProps => (
          <AirbnbEmailDetail
            {...props}
            notificationId={routeProps.match.params.notificationId}
          />
        )}
      />
      <Route
        path="/messaging/airbnb-custom-auto-messages"
        render={() => <AirbnbAutoMessages {...props} />}
      />
      <Route
        path="/messaging/rvshare-custom-auto-messages/:notificationId"
        render={routeProps => (
          <RvshareEmailDetail
            {...props}
            notificationId={routeProps.match.params.notificationId}
          />
        )}
      />
      <Route
        path="/messaging/rvshare-custom-auto-messages"
        render={() => <RvshareAutoMessages {...props} />}
      />
      <Route
        path="/messaging/homeaway-vrbo-custom-auto-messages/:notificationId"
        render={routeProps => (
          <VrboEmailDetail
            {...props}
            notificationId={routeProps.match.params.notificationId}
          />
        )}
      />
      <Route
        path="/messaging/homeaway-vrbo-custom-auto-messages"
        render={() => <VrboAutoMessages {...props} />}
      />
      <Route
        path="/messaging/direct-system-generated-auto-emails/:notificationId"
        render={routeProps => (
          <DirectSystemEmailDetail
            {...props}
            notificationId={routeProps.match.params.notificationId}
          />
        )}
      />
      <Route
        path="/messaging/direct-system-generated-auto-emails"
        render={() => <DirectSystemEmails {...props} />}
      />
      <Route
        path="/messaging/direct-customizable-auto-emails/:notificationId"
        render={routeProps => (
          <DirectCustomEmailDetail
            {...props}
            notificationId={routeProps.match.params.notificationId}
          />
        )}
      />
      <Route
        path="/messaging/direct-customizable-auto-emails"
        render={() => <DirectCustomizableEmails {...props} />}
      />
    </Switch>
  );
};

export default MessagingRoutes;
